<template>
  <div class="tag_line">
    <transition-group
      name="slide"
      tag="div"
      class="swiper-notification"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        v-show="currentIndex === index"
      >
        <h5>{{ slide.title }}</h5>
        <span>{{ slide.content }}</span>
      </div>
    </transition-group>
  </div>
</template>
<script>
import definition from "./definition";

const debug = require("debug")("atman.components.text_slider"); //eslint-disable-line

export default {
  name: "TextSlider",
  data() {
    return {
      definition,
      slides: [
        {
          title: "HELLO Test1",
          content: "test1",
        },
        { title: "HELLO Test2", content: "test2" },
        {
          title: "HELLO Test3",
          content: "test3",
        },
        { title: "HELLO Test4", content: "test4" },
      ],
      currentIndex: 0,
      autoplayInterval: null,
    };
  },
  mounted() {
    this.startAutoplay();
  },
  beforeDestroy() {
    this.stopAutoplay();
  },
  methods: {
    startAutoplay() {
      this.autoplayInterval = setInterval(() => {
        this.nextSlide();
      }, 5000);
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    slideDirection(newIndex, oldIndex) {
      return newIndex > oldIndex ? "left" : "right";
    },

    // After enter transition, reset the slide position
    afterEnter(el) {
      el.style.transform = "none";
    },

    // Before leave transition, set the slide position based on the direction
    beforeLeave(el) {
      const direction = this.slideDirection(
        this.currentIndex,
        this.previousIndex
      );
      if (direction === "left") {
        el.style.transform = "translateX(-100%)";
      } else {
        el.style.transform = "translateX(100%)";
      }
    },
  },
};
</script>
<style>
/* Swiper CSS styles */
/* You may need to adjust these styles based on your design requirements */

/* Required Swiper container styles */
.tag_line {
  height: auto;
  min-height: 58px;
  position: relative;
  background: #f5f5f5;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  text-align: center;
}

/* Required Swiper slide container styles */
.swiper-notification {
  display: flex; /* Display slides in a row */
  transition: transform 0.5s ease-in-out; /* Add smooth transition for sliding effect */
}

/* Required Swiper slide styles */
.swiper-notification > div {
  flex: 0 0 100%; /* Each slide takes the full width of the container */
  padding: 10px;
}

/* Optional styles for slide content */
.swiper-notification h5 {
  font-size: 18px;
  font-weight: bold;
}

.swiper-notification span {
  font-size: 14px;
  color: #333;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out; /* Add smooth transition for sliding effect */
}

.slide-enter {
  transform: translateX(
    0
  ); /* Start and end position for the slides during the transition */
}
.slide-leave-to {
  transform: translateX(
    0
  ); /* Start and end position for the slides during the transition */
}

/* Direction-specific transitions */
.slide-enter-to {
  transform: translateX(100%);
}

.slide-leave {
  transform: translateX(0);
}
</style>
