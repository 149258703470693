import bb, { gauge, pie, line, bar } from "billboard.js";
import "billboard.js/dist/billboard.css";
import { defaultsDeep } from "lodash";
const debug = require("debug")("atman.components.chart_factory");
debug("chart_factory");
const pattern = [
  "#FF0000",
  "#F97600",
  "#F6C600",
  "#60B044",
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
];
const gaugeDefaults = () => {
  return {
    data: {
      type: gauge(),
    },
    color: {
      threshold: {
        values: [30, 60, 90, 100],
      },
    },
  };
};

const gaugeFullCircle = () => {
  return {
    data: {
      type: gauge(),
    },
    gauge: {
      fullCircle: true,
    },
  };
};

const pieDefaults = () => {
  return {
    data: {
      type: pie(),
    },
  };
};
const pieLabelFormat = () => {
  return {
    data: {
      type: pie(),
    },
    pie: {
      label: {
        format: function (value) {
          return value;
        },
      },
    },
  };
};

const lineDefaults = () => {
  return {
    data: {
      type: line(),
    },
  };
};

const timeseriesDefaults = () => {
  return {
    data: {
      x: "x",
      type: line(),
    },
    axis: {
      x: {
        type: "timeseries",
        tick: {
          format: "%Y-%m-%d",
        },
      },
    },
  };
};

const barDefaults = (attributes) => {
  return {
    data: {
      type: bar(),
    },
    bar: {
      width: {
        ratio: 0.5,
      },
      padding: attributes?.padding || 0,
    },
  };
};

const barLabeled = () => {
  return {
    data: {
      type: bar(),
      labels: true,
    },
    bar: {
      width: {
        ratio: 0.5,
      },
    },
  };
};

const getDefaults = (type, data, attributes) => {
  let chartSpecificDefaults = {};
  switch (type) {
    case "gauge":
      chartSpecificDefaults = gaugeDefaults();
      break;
    case "gauge-fullcircle":
      chartSpecificDefaults = gaugeFullCircle();
      break;
    case "pie":
      chartSpecificDefaults = pieDefaults();
      break;
    case "pielabel":
      chartSpecificDefaults = pieLabelFormat();
      break;
    case "line":
      chartSpecificDefaults = lineDefaults();
      break;
    case "timeseries":
      chartSpecificDefaults = timeseriesDefaults();
      break;
    case "bar":
      chartSpecificDefaults = barDefaults(attributes);
      break;
    case "barlabel":
      chartSpecificDefaults = barLabeled();
      break;
    default:
      console.error(`Unsupported chart_type: ${type}`);
  }
  return defaultsDeep({ data }, chartSpecificDefaults, {
    color: {
      pattern: pattern,
    },
  });
};

const getColorPattern = (definition) => {
  const patternInConfig = definition?.chart_config?.color?.pattern;
  return patternInConfig || pattern;
};

const renderMixin = {
  methods: {
    render(definition, target) {
      const component = this;
      const data = definition?.value?.chart_data;
      const attributes = definition?.display?.attributes;

      let configuration = getDefaults(definition.chart_type, data, attributes);

      configuration = defaultsDeep(
        definition.chart_config || {},
        configuration,
        {
          bindto: target,
        }
      );
      if (definition.drilldown) {
        configuration.data.onclick = function (d, element) {
          component.displayDrilldown(d, element);
        };
      }
      debug(`${JSON.stringify(configuration)}`);
      return bb.generate(configuration);
    },
    displayDrilldown(d) {
      const component = this;
      const definition = component.value;
      const values = definition?.value?.chart_data.columns[d.index];
      const drilldownDefinition = {
        title: "Drilldown",
        chart_type: definition.drilldown,
        chart_config: {
          color: {
            pattern: [getColorPattern(definition)[d.index]],
          },
        },
        value: {
          id: "drilldown",
          chart_data: {
            columns: [[...values]],
          },
        },
      };
      debug(`drilldownDefinition`, drilldownDefinition);
      component.drilldownVisible = true;
      component.render(drilldownDefinition, component.$refs.chartDrilldown);
    },
  },
};

export { renderMixin };
