export default {
  label: "AutoText Slider field",
  type: "autotextslider",
  description: "Displays a AutoText Slider field",
  properties: ["name", "label", "type", "mode", "display", "block", "value"],
  templates: [
    {
      id: "default",
      label: "Default",
      variables: {
        data: {
          default: "",
        },
      },
      value: {
        display: {
          attributes: {
            width: "100%",
            height: 500,
          },
        },
        name: "default",
        label: "iFrame",
        type: "autotextslider",
      },
    },
  ],
};
