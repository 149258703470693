export default {
  type: "chart",
  label: "Chart",
  filters: ["list_editor"],
  description: "Displays a Chart",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description:
        'The default Chart Type field. Use <code>"chart_type": "gauge"</code> to display <b>Gauge</b> chart type.',
      label: "Gauge",
      value: {
        name: "chart_1",
        type: "chart",
        title: "Conversions",
        chart_type: "gauge",
        display: {
          width: "6",
        },
        value: {
          id: "conversions",
          chart_data: {
            columns: [["conversions", 18]],
          },
        },
      },
    },
    {
      id: "gauge_fullcircle",
      description:
        'The default Chart Type field. Use <code>"chart_type": "gauge-fullcircle"</code> to display <b>GaugeFullCircle</b> chart type.',
      label: "GaugeFullCircle",
      value: {
        name: "chart_7",
        type: "chart",
        title: "Conversions",
        chart_type: "gauge-fullcircle",
        display: {
          width: "6",
        },
        value: {
          id: "conversions",
          chart_data: {
            columns: [["A", 60]],
          },
        },
      },
    },
    {
      id: "pie",
      label: "Pie",
      description:
        'Use <code>"chart_type": "pie"</code> to display <b>Pie</b> chart type.\
       This example also shows how to configure a drilldown.',
      value: {
        name: "chart_2",
        type: "chart",
        title: "SLA violations",
        chart_type: "pie",
        display: {
          width: "6",
        },
        chart_config: {
          color: {
            pattern: ["#aec7e8", "#ff7f0e"],
          },
        },
        drilldown: "bar",
        value: {
          id: "sla_violations",

          chart_data: {
            columns: [
              ["under", 196, 11, 80, 436, 342, 364],
              ["over", 185, 250, 363, 376, 210, 266],
            ],
          },
        },
      },
    },
    {
      id: "pielabel",
      label: "PieLabel",
      description:
        'Use <code>"chart_type": "pielabel"</code> to display <b>PieLabel</b> ',
      value: {
        name: "chart_6",
        type: "chart",
        title: "SLA violations",
        chart_type: "pielabel",
        display: {
          width: "6",
        },
        value: {
          id: "sla_violations",

          chart_data: {
            columns: [
              ["under", 196],
              ["over", 185],
            ],
          },
        },
      },
    },
    {
      id: "line",
      label: "Line",
      description:
        'Use <code>"chart_type": "line"</code> to display <b>Line</b> chart type.',
      value: {
        name: "chart_3",
        type: "chart",
        title: "SLA violations",
        chart_type: "line",
        display: {
          width: "6",
        },
        value: {
          id: "sla_violations",
          chart_data: {
            columns: [
              ["under", 415, 370, 350, 38, 114, 309],
              ["over", 20, 400, 71, 439, 138, 95],
            ],
          },
        },
      },
    },
    {
      id: "timeseries",
      description:
        'Use <code>"chart_type": "timeseries"</code> to display <b>Timeseries</b> chart type.',
      label: "Timeseries",
      value: {
        name: "chart_4",
        type: "chart",
        title: "SLA violations",
        chart_type: "timeseries",
        display: {
          width: "6",
        },

        value: {
          id: "sla_violations_time",
          chart_data: {
            columns: [
              [
                "x",
                "2013-01-01",
                "2013-01-02",
                "2013-01-03",
                "2013-01-04",
                "2013-01-05",
                "2013-01-06",
              ],
              ["under", 103, 42, 76, 372, 63, 382],
              ["over", 119, 369, 72, 108, 374, 360],
            ],
          },
        },
      },
    },
    {
      id: "bar",
      label: "Bar",
      description:
        'Use <code>"chart_type": "bar"</code> to display <b>Bar</b> chart type.',
      value: {
        name: "chart_5",
        type: "chart",
        title: "SLA violations",

        chart_type: "bar",
        display: {
          width: "6",
          attributes: {
            padding: 10,
          },
        },

        value: {
          id: "sla_violations",
          chart_data: {
            columns: [
              ["under", 416, 173, 53, 33, 153, 469],
              ["over", 208, 168, 355, 79, 427, 278],
            ],
          },
        },
      },
    },
    {
      id: "barlabel",
      label: "BarLabel",
      description:
        'Use <code>"chart_type": "barlabel"</code> to display <b>Bar</b> chart type.',
      value: {
        name: "chart_8",
        type: "chart",
        title: "SLA violations",
        chart_type: "barlabel",
        display: {
          width: "6",
        },
        value: {
          id: "sla_violations",
          chart_data: {
            columns: [
              ["under", 416, 173, 53, 33, 153, 469],
              ["over", 208, 168, 355, 79, 427, 278],
            ],
          },
        },
      },
    },

    {
      id: "pie_data",
      keyword: ["tutorial"],
      label: "Dynamic Graph",
      description:
        "The data for this graph is fetched using an API.<br/>Use <code>refresh_interval</code> to periodically refresh the data.",
      value: {
        name: "chart_pie_data",
        type: "chart",
        title: "SLA violations",
        chart_type: "pie",
        display: {
          width: "6",
        },
        apis: {
          data: {
            refresh_interval: 5,
            url: "developer/charts_data/sla_violations",
            type: "get",
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "padding",
      type: "number",
      hint: "padding between bar charts",
      value: 10,
    },
  ],
};
